const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6940830",
    space_js: "//tapbfdh.htinfotech.cn/site/idcl-zj/openjs/f-v/static/j.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6940831",
    space_js: "//tapbfdh.htinfotech.cn/common/jed/production/mak-g-w/static/n.js"
  }
];

const Bnnaer = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6940832",
    space_js: "//tapbfdh.htinfotech.cn/site/k/common/f_en_blh/xu.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native1,
  Home_banner: Bnnaer,
  Detail_inter: Inter,
  Detail_native: Native1,
  Detail_banner: Bnnaer,
  List_native: Native1,
  List_banner: Bnnaer,
  Result_inter: Inter,
  Result_native: Native1,
  Result_banner: Bnnaer
};
